import React, {useEffect} from "react"
import { DistortionText, LiquidDistortionText } from 'react-text-fun';
import Layout from "../components/layout"
import Tshirt from "../components/tshirt"
import SEO from "../components/seo"
import {
  useWindowSize,
} from '@react-hook/window-size'
// import ModalThing from './../components/modal'
import './index.css'
import {graphql, useStaticQuery} from "gatsby";
import Cursor from "../components/cursor";

const Link = ({width}) => {
  return (
      <LiquidDistortionText
          text={width > 1000 ? "NYC 05/26 tickets" : "NYC tickets"}
          fontFamily={'AppleChancery'}
          fill={'black'}
          fontSize={48}
          speed={.15}
          volatility={0.02}
          // rotation={45.0}
          // distortX={1.5}
          // distortY={1.5}
          // noiseAmplitude={0.5}
          // noiseVolatility={.5}
          // cellWidth={.005}
          // cellRadius={1}
          // dodge={false}
          // dodgeY={0.1}
          // dodgeSpread={0.08}
      />
  )
}

const IndexPage = () => {
  const [width, height] = useWindowSize(
    360 /* initialWidth when there is no window */,
    720 /* initialHeight when there is no window */,
    {wait: 100}
  );

  const data = useStaticQuery(graphql`
    query {
      threedImage: file(relativePath: { eq: "balkans_shirt.png" }) {
        childImageSharp {
          resize (width: 500) {
            src
          }
        }
      }
      tomHead: file(relativePath: { eq: "tom_head.jpg" }) {
        childImageSharp {
          resize (width: 300) {
            src
          }
        }
      }
      shirtImage: file(relativePath: { eq: "shirt2.JPG" }) {
        childImageSharp {
          resize (width: 600) {
            src
          }
        }
      }
      posterImage: file(relativePath: { eq: "posters.png" }) {
        childImageSharp {
          resize (width: 900) {
            src
          }
        }
      }
      posterImageSm: file(relativePath: { eq: "posters.png" }) {
        childImageSharp {
          resize (width: 375) {
            src
          }
        }
      }
    }
  `);

  const [sizes, setSizes] = React.useState({});
  const [open, setOpen] = React.useState(false);
  const [loaded, setLoaded] = React.useState(false);
  const [focus, setFocus] = React.useState(false);
  useEffect(() => {
    getInvitory();
    // return (
    //   alert('Are you sure you want to enter BALKANS?')
    // )
  }, []); // empty-array means don't watch for any updates

  const getInvitory = () => {
    fetch('/.netlify/functions/getShirts', {
      method: 'get',
      headers: new Headers({ "Content-Type": "application/json" })
    }).then( (response) => {
      return response.json();
    }).then( (data) => {
      setSizes(data[0].data)
    });
  };

  const sendSlackSpying = (l) => {
    fetch(`/.netlify/functions/spying?link=${l}`, {
      method: 'post',
      headers: new Headers({ "Content-Type": "application/json" })
    }).then( (response) => {
      return response.json();
    }).then( (data) => {
      console.log(data)
    });
  };

  const OpenTabs = () => {
    if (typeof window !== 'undefined' && window) {
      for (let i = 0; i - 3 ; i++) {
        window.open('/', '_blank');
        // redirectWindow.location;
      }
    }
    sendSlackSpying("10 new tabs")
  };

  const OpenLink = () => {
    if (typeof window !== 'undefined' && window) {
        window.open('https://www.seetickets.us/event/Balkans/478683', '_blank');
    }
    sendSlackSpying("NYC 05/26 2022 tickets")
  };

  const OpenLink2 = () => {
    if (typeof window !== 'undefined' && window) {
      window.open('https://www.eventbrite.com/e/balkans-reunion-show-w-sword-ii-blammo-irrelevant-music-djs-tickets-314581721397', '_blank');
    }
    sendSlackSpying("ATL 06/03 2022 tickets")
  };

  // if (!loaded) {
  //   return(
  //     <div className={'loader-wrapper'}>
  //       <Loader
  //         type="MutatingDots"
  //         color="#00BFFF"
  //         height={100}
  //         width={100}
  //         // timeout={3000} //3 secs
  //       />
  //     </div>
  //   )
  // }

  return (
    <Layout
      poster={ width > 500 ? data.posterImage.childImageSharp.resize.src : data.posterImageSm.childImageSharp.resize.src }
    >
      <SEO title="Home" />
      <Cursor focused={focus} poster={data.posterImage.childImageSharp.resize.src} />
      <div className={ loaded ? 'loader-wrapper loaded' : "loader-wrapper"}>
        <p>Loading...</p>
      </div>
      <div style={{ marginBottom: `1.45rem` }}>
        <div className={'balkans-link-wrapper'}>
          <div className={'balkans-show'} style={{ display: `flex`, justifyContent: `center`, margin: '2rem 0 1rem 0' }} onClick={() => OpenLink()}>
            <LiquidDistortionText
                text={width > 1000 ? "NYC 05/26 tickets" : "NYC tickets"}
                fontFamily={'AppleChancery'}
                fill={'black'}
                fontSize={48}
                speed={.15}
                volatility={0.02}
                id={'nyc'}
                // rotation={45.0}
                // distortX={1.5}
                // distortY={1.5}
                // noiseAmplitude={0.5}
                // noiseVolatility={.5}
                // cellWidth={.005}
                // cellRadius={1}
                // dodge={false}
                // dodgeY={0.1}
                // dodgeSpread={0.08}
            />
          </div>
          <div className={'balkans-show'} style={{ display: `flex`, justifyContent: `center`, margin: '1rem 0 2rem 0' }} onClick={() => OpenLink2()}>
            <LiquidDistortionText
                text={width > 1000 ? "ATL 06/03 tickets" : "ATL tickets"}
                fontFamily={'AppleChancery'}
                fill={'black'}
                fontSize={48}
                speed={.15}
                volatility={0.02}
                id={'atl'}
                // rotation={45.0}
                // distortX={1.5}
                // distortY={1.5}
                // noiseAmplitude={0.5}
                // noiseVolatility={.5}
                // cellWidth={.005}
                // cellRadius={1}
                // dodge={false}
                // dodgeY={0.1}
                // dodgeSpread={0.08}
            />
          </div>
        </div>
        {/*<ModalThing*/}
        {/*  open={open}*/}
        {/*  openModal = { () => setOpen(true)}*/}
        {/*  closeModal = { () => setOpen(false)}*/}
        {/*  shirt={data.shirtImage.childImageSharp.resize.src}*/}
        {/*  sizes={sizes}*/}
        {/*  focus={ ()=> setFocus(true) }*/}
        {/*  blur={ ()=> setFocus(false) }*/}
        {/*/>*/}
        <div style={{ display: `flex`, justifyContent: `center` }} onClick={() => setOpen(true)}>
          <Tshirt
            shirt={data.posterImageSm.childImageSharp.resize.src}
            head={data.tomHead.childImageSharp.resize.src}
            open={open}
            width={width}
            height={height}
            loaded={ () => setLoaded(true) }
          />
        </div>
        <div className={'balkans-tabs'} style={{ display: `flex`, justifyContent: `center`, margin: '2rem 0' }} onClick={() => OpenTabs()}>
          <DistortionText
            text="Balkans tabs"
            fontFamily={'AppleChancery'}
            fill={'black'}
            fontSize={48}
            speed={.5}
            rotation={45.0}
            distortX={1.5}
            distortY={1.5}
            noiseAmplitude={0.5}
            noiseVolatility={.5}
          />
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
